import { Events } from "../../models/events";
import api from "../api";

const getAllEvents = (skip?: number, take?: number) => {
  return api({
    url: `/events?skip=${skip}&take=${take}`,
    method: "GET",
  });
};

const createEvents = (body: Events) => api.post("/event", body);

const updateEvents = (id: string, body: Events) =>
  api.put(`/event/${id}`, body);

const uploadImage = (id: string, formData: File) => {
  const fd = new FormData();
  fd.append("file", formData);

  return api.post(`/event/${id}/upload-photo`, fd, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export { getAllEvents, createEvents, updateEvents, uploadImage };
