import React from 'react'
import EventsTable from '../components/events/eventsTable'
import logo from '../assets/logo.png'

type Props = {}

const Home = (props: Props) => {

    return (

        <div className="hero min-h-screen" style={{
            backgroundImage: `url('https://images.pexels.com/photos/1485630/pexels-photo-1485630.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750')`,
        }}>
            <div className="hero-overlay bg-opacity-80"></div>
            <div className="hero-content text-center text-neutral-content">
                <div className="max-w-md text-gray-300">
                    <img className="w-64 mb-3 m-auto rounded-md" src={logo} alt="Prayas logo" />

                    <h1 className="mb-1 text-5xl font-bold tracking-widest">PRAYAS</h1>
                    <h1 className="mb-3 text-2xl font-bold font-mono tracking-widest">Connecting People</h1>

                    <p className="mb-5">
                        Welcome to Prayas,
                        <br /> a platform to connect with people.
                    </p>
                    {/* <button className="btn btn-primary">Get Started</button> */}
                </div>
            </div>
        </div>
    )
}
export default Home