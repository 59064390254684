import React, { useState } from 'react'
import { Tab } from '@headlessui/react'
import { Navigate, useLocation, useParams } from "react-router-dom";
import { useGetRelationsQuery, useGetUserQuery, useRemoveChildMutation, useRemoveFatherMutation, useRemoveMotherMutation, useRemoveSpouseMutation, useUpdateUserMutation } from '../app/services/userData';
import BackButton from '../components/utils/BackButton';
import { calculateFullAge, formatDate, formatPhoneNumber, formatTitleCase } from '../utils/utils';
import { Link } from 'react-router-dom';
import { Switch } from '@headlessui/react'
import { GoVerified } from "react-icons/go"
import { RenderProfileImage } from '../components/utils/RenderImage';
import Loading from '../components/utils/Loading';
import { User } from '../models/user';
import NotFound from './NotFound';
import { toast } from 'react-toastify';
import AddRelationSearch from '../components/user/addRelationSearch';
import AddUserWithRelation from '../components/user/adduserWithRelation';
import { GiVillage } from 'react-icons/gi';
import { FaWhatsappSquare } from 'react-icons/fa';
type Props = {}


const personalDetailsKeys = [
    'email',
    'gender',
    'dob',
    'landline',
    'occupation',
    'qualification',
    'maritalStatus'
];



const additionalDetailsKeys = [
    'bio',
    'hobbies',
    'bloodGroup',
    'guruJi',

];

const tabKeys = [
    'Family Details',
    'Personal Details',
    'Additional Details',
];

const UserDetails = (props: Props) => {
    let params = useParams();
    const id = params.id;
    const location = useLocation();

    const { data, error, isLoading, isFetching } = useGetUserQuery(id!);
    const [updateUser, { isError }] = useUpdateUserMutation();


    const [relation, setRelation] = useState('');

    const relations = useGetRelationsQuery(id);


    if (isLoading || relations.isLoading) {
        return <Loading />
    }

    if (!data || error || relations.error) {
        return <Navigate state={{ from: location }} to="/404" replace />
    }

    const handleToggleSwitch = async (change: boolean) => {
        if (!window.confirm('Are you sure you want hrefchange the status of this user?')) {
            return;
        }

        const updatedData = await updateUser({
            id: data!.id,
            isActive: change
        }).unwrap();

        if (updatedData) {
            toast.success('User updated successfully');
        }
    }

    const markDead = async () => {
        if (!window.confirm(`Are you sure you want hrefmark this user as ${data.isDeceased ? 'not dead' : 'dead'} ?`)) {
            return;
        }

        const updatedData = await updateUser({
            id: data!.id,
            isDeceased: !data.isDeceased
        }).unwrap();

        if (updatedData) {
            toast.success('User updated successfully');
        }
    }

    if (isError) {
        toast.error('Failed hrefupdate user');
    }

    return (
        <div>
            <BackButton />
            <div className="hero-content flex-col lg:flex-row m-auto">

                <figure className='p-2 bg-white rounded-md'>
                    <RenderProfileImage className={"max-w-xs rounded-xl shadow-2xl"} imageLocation={data!.profilePhoto!} firstName={data!.firstName} lastName={data!.lastName} />
                </figure>


                <div>
                    <h1 className="text-4xl font-bold flex items-center">{data.isDeceased ? <p className='text-2xl text-red-500'>(Late)</p> : null}&nbsp;{data.firstName} {data.lastName} {data.verified && <GoVerified size={25} className="rounded-full m-2 text-blue-600" />}</h1>
                    <p className="text-2xl font-semibold text-neutral flex items-center"><a href={`tel:${data?.mobile}`}>{formatPhoneNumber(data?.mobile!)}
                    </a>
                        {/* &nbsp; <a target="_blank" href={`https://wa.me/${data?.mobile}`}> <FaWhatsappSquare color='green' size={30} /></a> */}
                    </p>

                    {data?.nativeVillage && <h2 className="text-2xl font-bold mt-5 flex items-center"> <GiVillage /> {data?.nativeVillage.name}</h2>}
                    <Switch.Group>

                        <div className="flex items-center my-2">
                            <Switch.Label className="mr-4">Make profile {data!.isActive ? 'Inactive' : 'Active'}</Switch.Label>
                            <Switch
                                checked={data!.isActive ? true : false}
                                onChange={handleToggleSwitch}
                                className={`${data!.isActive ? 'bg-teal-700' : 'bg-red-700'}
          relative inline-flex flex-shrink-0 h-[38px] w-[74px] border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
                            >
                                <span className="sr-only">Make profile Active/Inactive</span>
                                <span
                                    aria-hidden="true"
                                    className={`${data!.isActive ? 'translate-x-9' : 'translate-x-0'}
                                    pointer-events-none inline-block h-[34px] w-[34px] rounded-full bg-white shadow-lg transform ring-0 transition ease-in-out duration-200`}
                                />
                            </Switch>
                        </div>
                        <button className={`btn ${data.isDeceased ? 'btn-success' : 'btn-error'}`} onClick={markDead}>Mark {data.isDeceased ? 'Not Deceased' : 'Deceased'}</button>

                    </Switch.Group>
                </div>



            </div>

            <Tab.Group defaultIndex={1}>
                <Tab.List className="flex p-1 space-x-1  rounded-xl">
                    {
                        tabKeys.map((key, index) => {
                            return (
                                <Tab key={index} className={({ selected }: { selected: boolean }) => (`${selected ? 'bg-white shadow-xl' : 'text-blue-100 hover:bg-white/[0.40] hover:text-white'} w-full py-2.5 text-sm font-semibold text-accent hover:text-accent rounded-lg border-2 border-accent-focus`)}>
                                    {key}
                                </Tab>
                            )
                        }
                        )}
                </Tab.List>
                <Tab.Panels>
                    <Tab.Panel>
                        <>
                            {<ProfileCard data={relations.data?.father} relation={'father'} id={id!} setRelation={setRelation} />}
                            {<ProfileCard data={relations.data?.mother} relation={'mother'} id={id!} setRelation={setRelation} />}
                            {<ProfileCard data={relations.data?.spouse} relation={'spouse'} id={id!} setRelation={setRelation} />}

                            <div className='divider font-bold ' >Children</div>


                            {relations.data?.children.map((child: { id: string; profilePhoto: string; firstName: string; lastName: string; }, index: number) => {
                                return <ProfileCard data={child} relation={`child`} key={index} id={id!} setRelation={setRelation} />
                            })}
                            <label htmlFor="add-relation-modal" className="btn modal-button" onClick={() => setRelation('child')}>Add Child</label>



                            <div className='divider font-bold' >Siblings</div>

                            {relations.data?.siblings.map((child: { id: string; profilePhoto: string; firstName: string; lastName: string; }, index: number) => {
                                return <ProfileCard data={child} relation={`sibling`} key={index} id={id!} setRelation={setRelation} />
                            })}
                            <label htmlFor="add-relation-modal" className="btn modal-button" onClick={() => setRelation('sibling')}>Add Sibling</label>


                            <AddRelationModal id={id!} relation={relation} />

                        </>


                    </Tab.Panel>
                    <Tab.Panel>
                        <>
                            <div className='flex justify-center flex-wrap'>
                                {personalDetailsKeys.map((key: string, index: number) => {
                                    return (
                                        <DetailsCard key={key} title={key === 'dob' ? calculateFullAge(data![key]) : data![key as keyof User]} subTitle={key === 'dob' ? 'age' : key} />
                                    )
                                })}
                                <DetailsCard title={data!['isDonor'] ? 'Yes' : 'No'} subTitle={'isDonor'} />

                            </div>
                            <div className='divider font-bold' >Address</div>

                            <div className='flex justify-center flex-wrap'>
                                {['addressLine', 'area', 'city', 'state', 'country', 'pincode'].map((key: string, index: number) => {
                                    return (
                                        <DetailsCard key={key} title={data![key as keyof User]} subTitle={key} />

                                    )
                                })}
                            </div>

                        </>
                    </Tab.Panel>
                    <Tab.Panel>
                        <>


                            <div className='flex justify-center flex-wrap'>
                                <DetailsCard title={formatDate(data!['anniversary'])} subTitle={'anniversary'} />
                                {additionalDetailsKeys.map((key: string, index: number) => {
                                    return (
                                        <DetailsCard key={key} title={key === 'dob' ? calculateFullAge(String(data![key as keyof User])) : data![key as keyof User]} subTitle={key === 'dob' ? 'age' : key} />
                                    )
                                })}

                            </div>
                            <div className='divider font-bold' >Office Address</div>

                            <div className='flex justify-center flex-wrap'>
                                {['officeAddress', 'officeCity', 'officeState', 'officeCountry', 'officePincode'].map((key: string, index) => {
                                    return (
                                        <DetailsCard key={key} title={data![key as keyof User]} subTitle={key} />

                                    )
                                })}
                            </div>


                        </>
                    </Tab.Panel>
                </Tab.Panels>
            </Tab.Group>




        </div >
    )
}


const AddRelationModal = (props: { id: string, relation: string }) => {

    return (
        <div>
            <input type="checkbox" id="add-relation-modal" className="modal-toggle" />
            <div className="modal modal-bottom">

                <div className="modal-box w-11/12 max-w-5xl">
                    <h3 className="font-bold text-lg">Adding {props.relation}</h3>
                    <div className='flex justify-between'>
                        <AddRelationSearch relation={props.relation} id={props.id} />
                        <AddUserWithRelation relation={props.relation} id={props.id} />
                    </div>
                    <div className="modal-action">
                        <label htmlFor="add-relation-modal" className="btn">Close</label>
                        <label htmlFor="add-relation-modal" className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>
                    </div>
                </div>
            </div>
        </div>
    )
}
type DetailsCardProps = {
    title: string | boolean | object | null,
    subTitle: string | null,
}

const DetailsCard = ({ title, subTitle }: DetailsCardProps) => {
    return (


        <div className="stats w-96 shadow-xl m-2">

            <div className="stat">
                <div className="stat-title">{subTitle && formatTitleCase(subTitle)}</div>
                <div className="stat-value text-2xl">{title ?? '-'}</div>
                {/* <div className="stat-desc"></div> */}
            </div>

        </div>

    )
}
type ProfileCardProps = {
    id: string,
    data: {
        id: string,
        profilePhoto: string,
        firstName: string,
        lastName: string,
    },
    relation: string,
    setRelation: React.Dispatch<React.SetStateAction<string>>

}


const ProfileCard = ({ data, id, relation, setRelation }: ProfileCardProps) => {

    const [removeFather] = useRemoveFatherMutation();
    const [removeMother] = useRemoveMotherMutation();
    const [removeSpouse] = useRemoveSpouseMutation();
    const [removeChild] = useRemoveChildMutation();

    const cardColor = (relation: string) => {
        switch (relation) {
            case 'father':
                return 'bg-grey-200'
            case 'mother':
                return 'bg-grey-200'
            case 'spouse':
                return 'bg-pink-600 text-white'
            case 'child':
                return 'bg-orange-300 text-white'
            case 'sibling':
                return 'bg-teal-300 '

            default:
                break;
        }
    }


    return (

        <div className="indicator m-4">
            <span className="indicator-item  indicator-top indicator-center badge badge-success p-3">{relation.toUpperCase()}</span>
            {
                data ? <div className="card card-compact w-40 bg-base-100 shadow-xl ">
                    <figure><RenderProfileImage imageLocation={data.profilePhoto} firstName={data.firstName} lastName={data.lastName} /></figure>
                    <div className={`card-body ${cardColor(relation)}`}>
                        <h2 className="card-title m-auto">{data.firstName} {data.lastName}</h2>
                        <div className="card-actions justify-between items-center">
                            <Link to={`/user/${data.id}`} className="btn btn-sm btn-outline btn-accent m-auto">View Profile</Link>

                            {(['father', 'mother', 'spouse', 'child'].includes(relation)) ?
                                <button className='btn btn-sm btn-error m-auto mt-4' onClick={
                                    () => {
                                        if (window.confirm('Are you sure you want hrefremove this relation?')) {
                                            // removeRelation(data.id, relation)

                                            switch (relation) {
                                                case 'father':
                                                    removeFather({ id: id }).unwrap().then(() => {
                                                        toast.success('Father removed successfully')
                                                    })

                                                    break;
                                                case 'mother':

                                                    removeMother({ id: id }).unwrap().then(() => {
                                                        toast.success('Mother removed successfully')
                                                    })
                                                    break;
                                                case 'spouse':

                                                    removeSpouse({ id: id }).unwrap().then(() => {
                                                        toast.success('Spouse removed successfully')
                                                    })
                                                    break;
                                                case 'child':
                                                    removeChild({ id: id, childId: data.id }).unwrap().then(() => {
                                                        toast.success('child removed successfully')
                                                    })
                                                    break;

                                                default:
                                                    break;
                                            }

                                        }
                                    }
                                }>Remove</button> : null}
                        </div>
                    </div>
                </div> : <div>
                    <div className="card card-compact w-40 bg-base-100 shadow-xl">
                        <figure><img src={`https://avatars.dicebear.com/api/croodles-neutral/no-data.svg`} alt={`no-image`} loading='lazy' /></figure>
                        <div className="card-body">
                            <h2 className="card-title m-auto">No Data</h2>

                            {(['father', 'mother', 'spouse'].includes(relation)) ?
                                <label htmlFor="add-relation-modal" className="btn modal-button" onClick={() => setRelation(relation)}>Add {relation}</label>
                                : null}
                        </div>
                    </div>

                </div>
            }

        </div >
    )
}

export default UserDetails