import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "../store";

export const eventsApi = createApi({
  reducerPath: "eventsApi",

  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API,
    prepareHeaders: (headers, { getState }) => {
      // By default, if we have a token in the store, let's use that for authenticated requests
      const token = (getState() as RootState).auth.token;
      if (token) {
        headers.set("authorization", token);
      }
      return headers;
    },
  }),
  tagTypes: [],
  endpoints: (builder) => ({
    getAllEvents: builder.query({
      query: (query: { skip: number; take: number }) =>
        `events?skip=${query.skip}&take=${query.take}`,
      // transformResponse: (response: any) => response.data,
    }),
    createEvents: builder.mutation({
      query: (body) => ({
        url: `event`,
        method: "POST",
        body,
      }),
    }),
    udpateEvents: builder.mutation({
      query: (body) => ({
        url: `event/${body.id}`,
        method: "PUT",
        body,
      }),
    }),
  }),
});

export const {
  useGetAllEventsQuery,
  useCreateEventsMutation,
  useUdpateEventsMutation,
} = eventsApi;
