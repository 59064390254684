import React, { useEffect } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { LoginRequest, useLoginMutation } from '../app/services/auth'
import { setUser } from '../features/authSlice'
import { ErrorMessage } from '@hookform/error-message';
import { useAppDispatch } from '../app/store'
import { useAuth } from '../hooks/useAuth'
import logo from '../assets/logo-bg.png'

type Props = {}

function Login(props: Props) {

    const dispatch = useAppDispatch()
    const { isAuthenticated } = useAuth()

    const navigate = useNavigate();

    const { register, handleSubmit, formState: { errors } } = useForm<LoginRequest>();

    const [login, { isLoading }] = useLoginMutation()

    const onSubmit: SubmitHandler<LoginRequest> = async (formData, e) => {

        try {
            const data = await login(formData).unwrap();
            dispatch(setUser({ user: data.data.user, token: data.data.token }))
            // navigate("/")
            toast.success('Login Successful')
        } catch (error) {
            toast.error((error as any).data.msg)
        }
    }

    useEffect(() => {
        if (isAuthenticated) {
            navigate("/")
        }
    }, [isAuthenticated])




    return (
        <div className="min-h-screen flex  ">

            <div className="flex items-center flex-col bg-white rounded-lg shadow-lg overflow-hidden m-auto w-10/12 lg:w-4/12">
                <div className="hidden lg:block lg:w-1/2 bg-cover" style={{
                    backgroundImage: `url('https://api.lorem.space/image/furniture?w=1000&h=800')`,
                }} >
                </div>

                <div className="w-full px-8 py-16 lg:w-3/4">
                    <img className="w-48 mb-3 m-auto rounded-md" src={logo} alt="Prayas logo" />
                    <h2 className="text-5xl font-bold text-gray-700 text-center tracking-widest">PRAYAS</h2>
                    <h2 className="text-2xl font-semibold text-gray-600 text-center tracking-widest">Connecting People</h2>

                    <p className="text-xl text-gray-600 text-center">Welcome back!</p>

                    <form onSubmit={handleSubmit(onSubmit)}>

                        <div className="form-control" >
                            <label className="label">
                                <span className="label-text">Email</span>
                            </label>
                            <input type="text" placeholder="email" className="input input-bordered" {...register("email", {
                                required: "This is required.",
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                    message: "Invalid email address"
                                }
                            })} />
                            <ErrorMessage errors={errors} name="email" render={
                                ({ message }) => {
                                    return <span className="italic text-red-300">{message}
                                    </span>
                                }} />
                        </div>
                        <div className="form-control">
                            <label className="label">
                                <span className="label-text">Password</span>
                            </label>
                            <input placeholder="password" type="password" className="input input-bordered" {...register("password", { required: "This is required." })} />
                            <ErrorMessage errors={errors} name="password" render={
                                ({ message }) => {
                                    return <span className="italic text-red-500">{message}</span>
                                }
                            } />
                        </div>
                        <div className="form-control mt-6 " >
                            <button className={`btn btn-primary ${isLoading ? 'loading' : ''}`} type='submit'>Login</button>
                        </div>
                    </form>

                </div>
            </div>
        </div>

    )
}

export default Login



