import { useMemo } from "react";
import { useAppSelector } from "../app/store";
import { selectCurrentUser } from "../features/authSlice";

export const useAuth = () => {
  const { isAuthenticated, user, isAdmin } = useAppSelector(selectCurrentUser);

  return useMemo(
    () => ({ isAuthenticated, user, isAdmin }),
    [isAuthenticated, isAdmin, user]
  );
};
