import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { authApi, useLogoutMutation, User, useRefreshTokenMutation } from '../app/services/auth'
import type { RootState } from '../app/store'
import axiosClient from '../services/api'
import { refreshToken } from '../services/auth/auth.api'

type AuthState = {
    user: User | null,
    token: string | null
    isAuthenticated: boolean
    isLoading: boolean
    isAdmin: boolean
}

const initialState: AuthState = {
    isAuthenticated: false,
    user: null,
    isLoading: true,
    isAdmin: false,
    token: null,
};

const slice = createSlice({
    name: 'auth',
    initialState: initialState,
    reducers: {
        setUser: (
            state: AuthState,
            { payload: { user, token } }: PayloadAction<{ user: User, token: string }>
        ) => {

            state.user = user;
            state.isAdmin = user.isAdmin;
            state.token = token;
            state.isAuthenticated = true;
            localStorage.setItem('loggedIn', 'true');
            localStorage.setItem('token', token);
            axiosClient.defaults.headers.common['Authorization'] = token;
            state.isLoading = false;

        },

        clearState: (state: AuthState) => {
            localStorage.clear()

            state.isAdmin = false;
            state.isAuthenticated = false;
            state.user = null;
            state.token = null;
            state.isLoading = false;

        }
    },
    extraReducers: (builder) => {
        // builder.addMatcher(
        //     authApi.endpoints.login.matchFulfilled,
        //     (state, { payload }) => {
        //         state.user = payload.data.user;
        //         state.isAdmin = payload.data.user.isAdmin;
        //         state.token = payload.data.token;
        //         state.isAuthenticated = true;
        //         axiosClient.defaults.headers.common['Authorization'] = payload.data.token
        //         localStorage.setItem('loggedIn', 'true');
        //         state.isLoading = false;
        //         // navigate("/")

        //         toast.success('Login successful')
        //     },
        // );

        builder.addMatcher(
            authApi.endpoints.logout.matchFulfilled,
            (state, { payload }) => {
                localStorage.clear();
                state = initialState;
                state.isLoading = false;
            },
        );
    }
})

export const { setUser, clearState } = slice.actions

export default slice.reducer

export const selectCurrentUser = (state: RootState) => state.auth
