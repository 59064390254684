import React from 'react'
import FeedbackTable from '../components/feedbacks/feedbackTable'
import Heading from '../components/utils/Heading'

type Props = {}

const Feedbacks = (props: Props) => {
    return (
        <div>
            <Heading title="Feedbacks" />
            <FeedbackTable />

        </div>
    )
}

export default Feedbacks