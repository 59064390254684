import {
  MiddlewareAPI,
  isRejectedWithValue,
  Middleware,
  isFulfilled,
} from "@reduxjs/toolkit";
import { toast } from "react-toastify";

/**
 * Log a warning and show a toast!
 */
export const rtkQueryErrorLogger: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
    if (isRejectedWithValue(action)) {
      if (action.payload.status === "FETCH_ERROR") {
        toast.warn("Something went Wrong!!  Please try again later.");
        toast.error("Server is down!");
      }

      if (action.payload.status === 400 && action.payload.data.msg) {
        toast.error(action.payload.data.msg);
      } else {
        toast.error("Something went Wrong!!  Please try again later.");
      }
      // if (action.payload.data) {
      //   console.warn("We got a rejected action!");
      //   toast.warn(action.payload.data.msg);
      // } else {
      //   toast.error("Something went Wrong!!  Please try again later.");
      // }
    }
    return next(action);
  };
