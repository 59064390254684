import { configureStore, ConfigureStoreOptions } from "@reduxjs/toolkit";
import { usersApi } from "./services/userData";
import { eventsApi } from "./services/events";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { authApi } from "./services/auth";
import authReducer from "../features/authSlice";
import imageModalReducer from "../features/imageModalSlice";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import { nativesApi } from "./services/natives";
import { rtkQueryErrorLogger } from "./error-middleware";

export const setupStore = (
  options?: ConfigureStoreOptions["preloadedState"] | undefined
) =>
  configureStore({
    reducer: {
      [usersApi.reducerPath]: usersApi.reducer,
      [eventsApi.reducerPath]: eventsApi.reducer,
      [authApi.reducerPath]: authApi.reducer,
      [nativesApi.reducerPath]: nativesApi.reducer,
      auth: authReducer,
      imageLink: imageModalReducer,
    },
    middleware: (getDefaultMiddleware: () => any[]) =>
      getDefaultMiddleware().concat(
        rtkQueryErrorLogger,
        usersApi.middleware,
        eventsApi.middleware,
        authApi.middleware,
        nativesApi.middleware
      ),
    ...options,
  });

export const store = setupStore();

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export type RootState = ReturnType<typeof store.getState>;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
setupListeners(store.dispatch);
