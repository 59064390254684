import { AdminAuth } from "../../models/admin-auth";
import api from "../api";

const getAllAdminAuth = (skip?: number, take?: number) =>
  api({
    url: `/admin-users?skip=${skip}&take=${take}`,
    method: "GET",
  });

const createAdminAuth = (body: AdminAuth) => api.post("/admin-user", body);

const updateAdminAuth = (id: string, body: AdminAuth) =>
  api.put(`/admin-user/${id}`, body);

export { getAllAdminAuth, createAdminAuth, updateAdminAuth };
