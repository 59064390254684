import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Natives } from "../../models/native";
import { RootState } from "../store";

export const nativesApi = createApi({
  reducerPath: "nativesApi",

  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API,
    prepareHeaders: (headers, { getState }) => {
      // By default, if we have a token in the store, let's use that for authenticated requests
      const token = (getState() as RootState).auth.token;
      if (token) {
        headers.set("authorization", token);
      }
      return headers;
    },
  }),
  tagTypes: ["Natives"],
  endpoints: (builder) => ({
    getAllNatives: builder.query<Natives[], { take: number; skip: number }>({
      query: (arg) => {
        const { take, skip } = arg;
        return {
          url: "natives",
          params: { take, skip },
        };
      },

      transformResponse: (response: any) => response.data,
      providesTags: ["Natives"],
    }),
  }),
});

export const { useGetAllNativesQuery } = nativesApi;
