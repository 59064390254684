import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { DummyUser, User } from "../../models/user";
import { RootState } from "../store";
// import env variable
export const usersApi = createApi({
  reducerPath: "usersApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API,
    prepareHeaders: (headers, { getState }) => {
      // By default, if we have a token in the store, let's use that for authenticated requests
      const token = (getState() as RootState).auth.token;
      if (token) {
        headers.set("authorization", token);
      }
      return headers;
    },
  }),
  tagTypes: ["User", "Relations"],
  endpoints: (builder) => ({
    getAllUsers: builder.query<User[], void>({
      query: () => `users`,
      transformResponse: (response: any) => response.data,
    }),
    getUser: builder.query<User, string>({
      transformResponse: (response: any) => response.data,
      query: (id) => `user/${id}`,
      providesTags: ["User"],
    }),
    searchUsers: builder.query({
      query: (searchString) => ({
        url: "search/user-by-name",
        method: "POST",
        body: { searchString: searchString, skip: 0, limit: 50 },
        transformResponse: (response: any) => response.data,
      }),
    }),
    searchActiveUsers: builder.query({
      query: (searchString) => ({
        url: "search/active-user-by-name",
        method: "POST",
        body: { searchString: searchString },
        transformResponse: (response: any) => response.data,
      }),
    }),
    updateUser: builder.mutation<User, Partial<User> & Pick<User, "id">>({
      query: (body) => ({
        url: `user/${body.id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["User"],
    }),
    getRelations: builder.query({
      query: (id) => `user/${id}/relations`,
      transformResponse: (response: any) => response.data,
      providesTags: ["Relations"],
    }),
    getUserByNativeId: builder.query({
      query: ({ id, skip, take }) => ({
        url: `/native/${id}/users?skip=${skip}&take=${take}`,
        method: "GET",
        transformResponse: (response: any) => response.data,
      }),
      providesTags: ["User"],
    }),
    createDummyUser: builder.mutation<DummyUser, Partial<User>>({
      query: (body) => ({
        url: `dummy-user`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["User"],
    }),
    addChild: builder.mutation<User, { id: string; childId: string }>({
      query: ({ id, childId }) => ({
        url: `user/${id}/add-child/${childId}`,
        method: "PUT",
      }),
      invalidatesTags: ["Relations"],
    }),
    addSiblings: builder.mutation<User, { id: string; siblingId: string }>({
      query: ({ id, siblingId }) => ({
        url: `user/${id}/add-sibling/${siblingId}`,
        method: "PUT",
      }),
      invalidatesTags: ["Relations"],
    }),
    addFather: builder.mutation<User, { id: string; fatherId: string }>({
      query: ({ id, fatherId }) => ({
        url: `user/${id}/add-father/${fatherId}`,
        method: "PUT",
      }),
      invalidatesTags: ["Relations"],
    }),
    addMother: builder.mutation<User, { id: string; motherId: string }>({
      query: ({ id, motherId }) => ({
        url: `user/${id}/add-mother/${motherId}`,
        method: "PUT",
      }),
      invalidatesTags: ["Relations"],
    }),
    addSpouse: builder.mutation<User, { id: string; spouseId: string }>({
      query: ({ id, spouseId }) => ({
        url: `user/${id}/add-spouse/${spouseId}`,
        method: "PUT",
      }),
      invalidatesTags: ["Relations"],
    }),
    removeFather: builder.mutation<User, { id: string }>({
      query: ({ id }) => ({
        url: `user/${id}/remove-father`,
        method: "PUT",
      }),
      invalidatesTags: ["Relations"],
    }),
    removeMother: builder.mutation<User, { id: string }>({
      query: ({ id }) => ({
        url: `user/${id}/remove-mother`,
        method: "PUT",
      }),
      invalidatesTags: ["Relations"],
    }),
    removeSpouse: builder.mutation<User, { id: string }>({
      query: ({ id }) => ({
        url: `user/${id}/remove-spouse`,
        method: "PUT",
      }),
      invalidatesTags: ["Relations"],
    }),
    removeChild: builder.mutation<User, { id: string; childId: string }>({
      query: ({ id, childId }) => ({
        url: `user/${id}/remove-child/${childId}`,
        method: "PUT",
      }),
      invalidatesTags: ["Relations"],
    }),
    addUserWithRelations: builder.mutation<
      User,
      { id: string; user: DummyUser; relation: string }
    >({
      query: ({ id, user, relation }) => ({
        url: `user/${id}/dummy-user-with-relation`,
        method: "POST",
        body: { user, relation },
      }),
      invalidatesTags: ["Relations"],
    }),
  }),
});

export const {
  useGetAllUsersQuery,
  useGetUserQuery,
  useSearchUsersQuery,
  useSearchActiveUsersQuery,
  useGetUserByNativeIdQuery,
  useGetRelationsQuery,
  useUpdateUserMutation,
  useCreateDummyUserMutation,
  useAddChildMutation,
  useAddSiblingsMutation,
  useRemoveFatherMutation,
  useRemoveMotherMutation,
  useRemoveSpouseMutation,
  useAddFatherMutation,
  useAddMotherMutation,
  useAddSpouseMutation,
  useAddUserWithRelationsMutation,
  useRemoveChildMutation,
} = usersApi;
