import React from 'react'
import Adduser from '../components/user/adduser'

type Props = {}

const UserData = (props: Props) => {
    return (
        <div>
            <Adduser />
        </div>
    )
}

export default UserData