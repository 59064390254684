import { Feedback } from "../../models/feedbacks";
import api from "../api";

const getAllFeedbacks = (skip?: number, take?: number) =>
  api({
    url: `/feedbacks?skip=${skip}&take=${take}`,
    method: "GET",
  });

const createFeedback = (body: Feedback) => api.post("/feedback", body);

const updateFeedback = (id: string, body: Feedback) =>
  api.put(`/feedback/${id}`, body);

export { getAllFeedbacks, createFeedback, updateFeedback };
