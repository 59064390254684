import React from 'react'

type Props = {
    imageLocation: string,
    imageAlt: string,
}

const RenderImage = ({ imageAlt, imageLocation }: Props) => {
    if (!imageLocation) {
        return <></>
    }

    return (
        //     <div className='overflow-hidden'>
        //     <img className='hover:scale-110' src={process.env.REACT_APP_S3_URI + imageLocation} alt={imageAlt} />
        // </div>

        <img src={process.env.REACT_APP_S3_URI + imageLocation} alt={imageAlt} loading='lazy' />
    )
}



type profileImgProps = {
    imageLocation: string,
    firstName: string,
    lastName: string,
    className?: string,
}

const RenderProfileImage = ({ imageLocation, className, firstName, lastName }: profileImgProps) => {

    return (

        <img className={className} src={imageLocation ? `${process.env.REACT_APP_S3_URI + imageLocation}` : `https://avatars.dicebear.com/api/initials/${firstName + ' ' + lastName}.png`} alt={`${firstName + lastName}`} />
    )
}


export { RenderImage, RenderProfileImage }