import { useAuth } from "../hooks/useAuth";
import Events from "../pages/Events";
import Home from "../pages/Home";
import Natives from "../pages/natives";

export const NavbarRoutes = [
  {
    path: "/",
    label: "Home",
  },
  {
    path: "/add-users",
    label: "Users",
  },
  {
    path: "/search",
    label: "Search User",
  },
  {
    path: "/events",
    label: "Events",
  },
  {
    path: "/natives",
    label: "Natives",
  },
  {
    path: "/biodata",
    label: "Biodata",
  },
  {
    path: "/feedbacks",
    label: "Feedbacks",
  },
];
