import React, { useEffect, useState } from 'react'
import { DataGrid, } from 'devextreme-react'
import { Column, Form, Popup, ColumnFixing, Editing, Pager, Paging, Scrolling, Summary, TotalItem, RemoteOperations, Selection, Sorting } from 'devextreme-react/data-grid';
import { Item, SimpleItem } from 'devextreme-react/form';
import { toast } from 'react-toastify';
import { createFeedback, getAllFeedbacks, updateFeedback } from '../../services/feedbacks/feedback.api';

import { Feedback } from '../../models/feedbacks';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import { Link } from 'react-router-dom';



type Props = {}

const FeedbacksTable = (props: Props) => {


    const store = new CustomStore({
        key: 'id',
        errorHandler: (error: any) => {
            toast.error(error.response.data.msg);
        },

        load: async function (loadOptions) {

            const res = await getAllFeedbacks(loadOptions.skip, loadOptions.take);
            return {
                data: res.data.data,
                totalCount: res.data.count,
                summary: [res.data.count]
            }
        },

        insert: async function (event: Feedback) {
            const res = await createFeedback(event);
            return res.data
        },

        update: async function (id: string, event: Feedback,) {

            const res = await updateFeedback(id, event);
            return res.data
        },

        onInserted: function (e) {
            toast.success('Feedback Created Successfully');
        },

        onUpdated: function (e) {
            toast.success('Feedback Updated Successfully');
        },
    });

    // ===== or inside the DataSource =====
    const dataSource = new DataSource({
        store: store,
    });

    const feedbackStatusOptions = ['REPORTED', 'IN_PROGRESS', 'RESOLVED']

    const allowedPageSizes = [5, 10, 15, 20];

    return (
        <>
            <DataGrid
                dataSource={dataSource}
                columnAutoWidth={true}
                showBorders={true}
                wordWrapEnabled={true}
                rowAlternationEnabled={true}
                columnHidingEnabled={true}
            >
                <Sorting mode="none" />
                <RemoteOperations
                    // filtering={true}
                    paging={true}
                    // sorting={true}
                    summary={true}
                // grouping={true}
                // groupPaging={true}
                />
                <Paging
                    defaultPageSize={5} />
                <Scrolling columnRenderingMode={'virtual'} rowRenderingMode="virtual" />
                <ColumnFixing enabled={true} />
                <Pager
                    showPageSizeSelector={true}
                    allowedPageSizes={allowedPageSizes}
                    showNavigationButtons={true}
                    showInfo={true}
                    visible={true}
                />
                <Editing
                    mode="popup"
                    allowUpdating={true}
                >
                    <Popup title="Feedback Info" showTitle={true} width={700} height={525} />
                    <Form>


                        <SimpleItem dataField="status" isRequired colSpan={2} editorType={'dxSelectBox'}
                            editorOptions={
                                {
                                    dataSource: feedbackStatusOptions
                                }
                            } />


                        {/* <SimpleItem dataField="title" isRequired colSpan={2} />
                        <Item itemType="group" colCount={2} colSpan={2}>

                            <SimpleItem dataField="contact" />
                            <SimpleItem dataField="status" isRequired editorType={'dxSelectBox'}
                                editorOptions={
                                    {
                                        dataSource: feedbackStatusOptions
                                    }
                                } />

                        </Item> */}
                        {/* <SimpleItem dataField="description" editorType={'dxTextArea'} colSpan={2} /> */}

                    </Form>
                </Editing>


                <Column dataField="title" />
                <Column dataField="description" width={300} />
                <Column dataField="contact" />
                <Column dataField="status" alignment={'center'} cellRender={
                    ({ data }) => {

                        switch (data.status) {
                            case 'REPORTED':
                                return <div className="badge">{data.status}</div>
                            case 'IN_PROGRESS':
                                return <div className="badge badge-warning">{data.status}</div>
                            case 'RESOLVED':
                                return <div className="badge badge-success">{data.status}</div>
                            default:
                                return <div className="badge badge-primary">{data.status}</div>
                        }

                    }
                } />
                <Column dataField="doneBy" cellRender={
                    ({ data }) => {
                        return data.doneById ? <Link className='link link-primary' to={`/user/${data.doneById}`}>
                            {data.doneBy.firstName} {data.doneBy.lastName}
                        </Link>
                            : null
                    }
                } />
                <Column dataField="createdAt" dataType="datetime" format={'dd/MM/yyyy hh:mm aa'} allowEditing={false} />
                <Column dataField="updatedAt" dataType="datetime" format={'dd/MM/yyyy hh:mm aa'} allowEditing={false} />
                <Summary>
                    <TotalItem column="title" summaryType="count" displayFormat="Total {0} comaplaints" />

                </Summary>
            </DataGrid>

        </>
    )
}

export default FeedbacksTable