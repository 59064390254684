import React from 'react'

type Props = {
    title: string,
    subtitle?: string,
}

const Heading = ({ title, subtitle }: Props) => {
    return (<>
        <h1 className="mb-5 text-5xl text-primary font-bold text-center">{title}</h1>
        <p className="mb-5 font-semibold">{subtitle}</p>

    </>
    )
}

export default Heading