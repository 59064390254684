import React, { useEffect } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import './App.css';
import Footer from './components/footer/footer';
import Navbar from './components/navbar/navbar';
import Events from './pages/Events';
import Home from './pages/Home';
import UserData from './pages/UserData';

import 'devextreme/dist/css/dx.light.css';
import 'react-toastify/dist/ReactToastify.css';

import { ToastContainer } from 'react-toastify';
import Search from './pages/Search';
import UserDetails from './pages/UserDetails';
import Login from './pages/Login';
// import { PrivateRoute } from './utils/PrivateRoute';
import { useAuth } from './hooks/useAuth';
import Natives from './pages/natives';
import NativeUser from './pages/NativeUser';
import Feedbacks from './pages/Feedbacks';
import Biodatas from './pages/Biodatas';
import { clearState, setUser } from './features/authSlice';
import { RootState, useAppDispatch } from './app/store';
import { PrivateRoute } from './utils/PrivateRoute';
import { useSelector } from 'react-redux';
import NotFound from './pages/NotFound';
import Admin from './pages/Admin';
import ImageModal from './components/utils/ImageModal';
import Loading from './components/utils/Loading';
import { useRefreshTokenMutation } from './app/services/auth';

function App() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate();

  const { isAdmin, isAuthenticated, user } = useAuth()
  const { isLoading } = useSelector((state: RootState) => state.auth)

  const [refreshToken, { data, isError, error }] = useRefreshTokenMutation()


  const handleAppStart = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      dispatch(clearState());
      return;
    }
    const data = await refreshToken({ token: token }).unwrap();
    if (data) {
      dispatch(setUser({ user: data.data.user, token: data.data.token }))
    }
  }

  if (isError) {
    navigate("/login")
    dispatch(clearState());
  }


  // useEffect(() => {
  //   if (!isAuthenticated) {
  //     navigate("/login")

  //   }
  // }, [isAuthenticated]);

  useEffect(() => {
    handleAppStart()
  }, [])

  if (isLoading) {
    return <Loading />
  }

  return (
    <div className="bg-transparent">
      <ToastContainer newestOnTop />

      <ImageModal />

      <Routes>

        <Route path='/login' element={<Login />} />

        <Route element={<PrivateRoute />}>
          <Route path='/' element={<Home />} />
          <Route path="/add-users" element={<UserData />} />
          <Route path="/user/:id" element={<UserDetails />} />

          <Route path="/search" element={<Search />} />

          <Route path="/natives" element={<Natives />} />
          <Route path="/native/:id/user" element={<NativeUser />} />

          <Route path="/events" element={<Events />} />
          <Route path="/biodata" element={<Biodatas />} />
          <Route path="/feedbacks" element={<Feedbacks />} />

          <Route path="/admin" element={isAdmin ? <Admin /> : <NotFound />} />
        </Route>


        <Route path='*' element={<NotFound />} />

      </Routes>
    </div>
  );
}

export default App;
