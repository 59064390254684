import axios from "axios";
import { AxiosRequestConfig } from "axios";
import { AxiosResponse } from "axios";
import { AxiosError } from "axios";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useAppDispatch } from "../app/store";
import { clearState } from "../features/authSlice";

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  withCredentials: true,
});

axiosClient.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (!error.response) {
      // network error
      toast.error("Network Error!! Can't connect to server");
    } else {
      let res = error.response;
      if (res.status === 401) {
        toast.error("Unauthorized");
        localStorage.clear();
        window.location.href = "/login";
      }

      toast.error(res.data.message);
      return Promise.reject(error);
    }
  }
);

export default axiosClient;
