import React, { useEffect, useRef, useState } from "react";
import { DataGrid } from "devextreme-react";
import {
  Column,
  Form,
  Popup,
  ColumnFixing,
  Editing,
  Pager,
  Paging,
  Scrolling,
  Summary,
  TotalItem,
  RemoteOperations,
  Selection,
  GroupPanel,
  Sorting,
  Button as DxButton,
} from "devextreme-react/data-grid";
import { Item, SimpleItem } from "devextreme-react/form";
import { toast } from "react-toastify";

import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";
import { createNatives, getAllNatives, updateNatives } from "../../services/natives/nativeApi";
import { Natives, NativeWithUser } from "../../models/native";
import { Link } from "react-router-dom";
import { FaFemale, FaMale } from "react-icons/fa";

type Props = {};

const NativeTable = (props: Props) => {
  const dataGridRef = useRef(null);

  const store = new CustomStore({
    key: "id",
    errorHandler: (error: any) => {
      toast.error(error.response.data.msg);
    },

    load: async function (loadOptions) {

      const res = await getAllNatives(loadOptions.skip, loadOptions.take);
      return {
        data: res.data.data as NativeWithUser[],
        totalCount: res.data.count,
        summary: [res.data.count],
      }
    },


    insert: async function (native: Natives) {
      const res = await createNatives(native);
      return res.data;
    },

    update: async function (id: string, native: Natives) {
      const res = await updateNatives(id, native);
      return res.data;
    },

    onInserted: function (e) {
      toast.success("Native Village Created Successfully");
    },

    onUpdated: function (e) {
      toast.success("Native Village Updated Successfully");
    },
  });

  // ===== or inside the DataSource =====
  const dataSource = new DataSource({
    store: store,
  });

  const allowedPageSizes = [10, 20, 50];

  return (
    <DataGrid
      ref={dataGridRef}
      dataSource={dataSource}
      columnAutoWidth={true}
      showBorders={true}

      rowAlternationEnabled={true}
      columnHidingEnabled={true}

    >
      <Sorting mode="none" />
      <RemoteOperations
        // filtering={true}
        paging={true}
        sorting={true}
        summary={true}
      // grouping={true}
      // groupPaging={true}
      />
      <Paging defaultPageSize={10} />
      <Scrolling columnRenderingMode={"virtual"} rowRenderingMode="virtual" />
      <ColumnFixing enabled={true} />
      <Pager
        showPageSizeSelector={true}
        allowedPageSizes={allowedPageSizes}
        showNavigationButtons={true}
        showInfo={true}
        visible={true}
      />
      {/* <Selection mode="single" /> */}
      <Editing
        mode="popup"
        allowUpdating={true}
        allowAdding={true}
      // allowDeleting={true}
      >
        <Popup title="Natives Village Info" showTitle={true} width={700} height={525} />
        <Form>
          <Item itemType="group" colCount={2} colSpan={2}>
            <SimpleItem dataField="name" isRequired />

          </Item>


          <SimpleItem
            dataField="isActive"
            helpText={"Make the Village Inactive. No more people can join from this village."}
          />
        </Form>
      </Editing>

      <Column dataField="name" cellRender={
        ({ data }) => {
          return <p className="font-semibold text-lg">{data.name}</p>
        }
      } />

      <Column dataField="id" caption="Details" alignment={'center'} cellRender={
        ({ data }) => {
          return <>
            <Link to={`/native/${data.id}/user`} className={`btn btn-xs ${data._count.UserData === 0 ? 'btn-disabled' : 'btn-accent'}`}>Users </Link>
            &nbsp;
            <span className="badge badge-ghost" title="Total"> Total {data._count.UserData}</span>
            &nbsp;
            <span className="badge badge-ghost" title="MALE"> <FaMale /> {data.maleCount}</span>
            &nbsp;
            <span className="badge badge-ghost" title="FEMALE"> <FaFemale /> {data.femaleCount}</span>

          </>
        }
      } />


      <Column dataField="isActive" dataType="boolean"
        cellRender={
          ({ value }) => {
            return value ? <span className="badge badge-success">Active</span> : <span className="badge badge-error">Inactive</span>
          }
        }

      />
      <Column dataField="createdAt" dataType="datetime" format={'dd/MM/yyyy hh:mm aa'} />

      <Column dataField="updatedAt" dataType="datetime" format={'dd/MM/yyyy hh:mm aa'} />
      <Column type="buttons" alignment="center" >
        <DxButton name="edit"
          render={
            (_props: any) => {
              return <button className="btn btn-secondary btn-sm" onClick={() => {
                // @ts-ignore
                return dataGridRef.current?.instance.editRow(_props.rowIndex)
              }}>Edit</button>
            }}
        />

      </Column>

      <Summary>
        <TotalItem
          column="name"
          summaryType="count"
          displayFormat="Total {0} villages"
        />
      </Summary>
    </DataGrid >
  );
};

export default NativeTable;
