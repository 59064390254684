import React from 'react'
import BiodatasTable from '../components/biodatas/BiodatasTable'
import Heading from '../components/utils/Heading'

type Props = {}

const Biodatas = (props: Props) => {
    return (
        <div>
            <Heading title="Biodatas - MALE" />
            <BiodatasTable gender='MALE' />

            <br />
            <Heading title="Biodatas - FEMALE" />
            <BiodatasTable gender='FEMALE' />


        </div>
    )
}

export default Biodatas