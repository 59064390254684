import React from 'react'
import EventsTable from '../components/events/eventsTable'
import Heading from '../components/utils/Heading'

type Props = {}

const Events = (props: Props) => {
    return (
        <div>
            <Heading title="Events" />
            <EventsTable />

        </div>
    )
}

export default Events