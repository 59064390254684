import { format, intervalToDuration } from "date-fns";
import { Natives } from "../models/native";

const formatPhoneNumber = (str: string) => {
  //Filter only numbers from the input
  let cleaned = ("" + str).replace(/\D/g, "");

  //Check if the input is of correct length
  let match = cleaned.match(/^(\d{2})(\d{5})(\d{5})$/);

  if (match) {
    return "(+" + match[1] + ") " + match[2] + "-" + match[3];
  }

  return null;
};

function calculateFullAge(dob: string | null) {
  if (!dob) return null;

  const date = new Date(dob);
  const { years, months, days } = intervalToDuration({
    start: date,
    end: new Date(),
  });

  //   console.log({ years, months, days });

  return years + " years";
  //   return { years, months, days };
}

function formatDate(date: string | null) {
  if (!date) return null;
  const d = new Date(date);

  return format(d, "MMMM dd, yyyy");
}

var formatTitleCase = (s: string) =>
  s
    .replace(/(^|[_-])([a-z])/g, (a, b, c) => c.toUpperCase())
    .replace(/([a-z])([A-Z])/g, (a, b, c) => `${b} ${c}`);

const getNativeName = (id: string, allNatives: Natives[] | undefined) => {
  if (!allNatives) return null;

  const native = allNatives.find((n) => n.id === id);
  if (!native) return null;

  return native.name;
};

export {
  formatPhoneNumber,
  getNativeName,
  formatDate,
  calculateFullAge,
  formatTitleCase,
};
