import React from 'react'
import NativesTable from '../components/natives/NativeTable'
import Heading from '../components/utils/Heading'

type Props = {}

const Natives = (props: Props) => {
    return (
        <div>
            <Heading title="Natives" />
            <NativesTable />

        </div>
    )
}

export default Natives