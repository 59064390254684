import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

type Props = {}

const NotFound = (props: Props) => {
    const navigate = useNavigate();

    return (
        <>
            <h1 className='text-5xl font-bold text-orange-300 text-center'>404 | Not Found</h1>

            <Link to='/' className='flex justify-center items-center m-4' >

                <button className="btn btn-circle btn-outline" onClick={() => navigate('/')}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M10 19l-7-7m0 0l7-7m-7 7h18" />
                    </svg>
                </button>

                <h4 className='m-4'>
                    Go back to Home
                </h4>
            </Link>

        </>
    )
}

export default NotFound