import React from 'react'
import AdminAuthTable from '../components/admin/adminTable'
import Heading from '../components/utils/Heading'

type Props = {}

const Admin = (props: Props) => {
    return (
        <div>
            <Heading title="Admin Accounts" />


            <AdminAuthTable />

        </div>
    )
}

export default Admin