import React from 'react'
import { useNavigate } from 'react-router-dom'

type Props = {}

const BackButton = (props: Props) => {
    const navigate = useNavigate();
    return (
        <div className='flex justify-left items-center'>
            <button className="btn btn-circle btn-outline" onClick={() => navigate(-1)}>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M10 19l-7-7m0 0l7-7m-7 7h18" />
                </svg>
            </button>
            <h3 className='m-4'>Go Back</h3>

        </div>
    )
}

export default BackButton