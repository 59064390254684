import { Profiles } from "../../models/profiles";
import api from "../api";

const getAllProfiles = (skip?: number, take?: number, gender?: string) =>
  api({
    url: `/profiles?skip=${skip}&take=${take}&gender=${gender}`,
    method: "GET",
  });

const createProfiles = (body: Profiles) => api.post("/profile", body);

const updateProfiles = (id: string, body: Profiles) =>
  api.put(`/profile/${id}`, body);

const uploadPhoto = (id: string, formData: File) => {
  const fd = new FormData();
  fd.append("file", formData);

  return api.post(`/profile/${id}/upload-photo`, fd, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
export { getAllProfiles, createProfiles, uploadPhoto, updateProfiles };
