import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export interface User {
  id: string;
  email: string;
  isAdmin: boolean;
}

export interface UserResponse {
  data: any;
  success: boolean;

  // user: User;
  // token: string;
}

export interface LoginRequest {
  email: string;
  password: string;
}

export const authApi = createApi({
  reducerPath: "authApi",

  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API,
    // prepareHeaders: (headers, { getState }) => {
    //   // By default, if we have a token in the store, let's use that for authenticated requests
    //   const token = (getState() as RootState).auth.token;
    //   if (token) {
    //     headers.set("authorization", token);
    //   }
    //   return headers;
    // },
    credentials: "include",
  }),
  refetchOnReconnect: true,
  endpoints: (builder) => ({
    login: builder.mutation<UserResponse, LoginRequest>({
      query: (credentials) => ({
        url: "login",
        method: "POST",
        body: credentials,
        // responseHandler: (response) => {
        //   return response.data;
        // },
        // transformResponse: (response: any) => response.data.data.user,
      }),
    }),
    refreshToken: builder.mutation({
      query: ({ token }) => ({
        url: "refresh-token",
        method: "POST",
        headers: {
          authorization: token,
        },
      }),
    }),
    logout: builder.mutation({
      query: () => ({
        url: "logout",
        method: "GET",
      }),
    }),
  }),
});

export const { useLoginMutation, useRefreshTokenMutation, useLogoutMutation } =
  authApi;
