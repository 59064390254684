import React from 'react'
import { useAppSelector } from '../../app/store';
import { RenderImage } from './RenderImage'

type Props = {

}

const ImageModal = (props: Props) => {

    const data = useAppSelector((state) => state.imageLink.imageLink);

    return (
        <div>
            <div className="modal modal-bottom sm:modal-middle" id="image-modal">
                <div className="modal-box">
                    {data ? <RenderImage imageLocation={data} imageAlt={'Failed to Load Image'} /> : null}
                    <div className="modal-action">
                        <a href="#" className="btn">Close</a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ImageModal