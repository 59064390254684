import React from 'react'
import { toast } from 'react-toastify';
import { useGetUserQuery } from '../app/services/userData';
import SearchBar from '../components/user/searchBar'

type Props = {}

const Search = (props: Props) => {

    // const [searchUserId, setSearchUserId] = React.useState('');
    // const { data, error, isLoading } = useGetUserQuery(searchUserId);

    return (
        <SearchBar />
    )
}

export default Search