import { Natives } from "../../models/native";
import api from "../api";

const getAllNatives = (skip?: number, take?: number) =>
  api({
    url: `/natives?skip=${skip}&take=${take}`,
    method: "GET",
  });

const createNatives = (body: Natives) => api.post("/native", body);

const updateNatives = (id: string, body: Natives) =>
  api.put(`/native/${id}`, body);

const getUserByNativeId = (id: string, skip?: number, take?: number) =>
  api({
    url: `/native/${id}/users?skip=${skip}&take=${take}`,
    method: "GET",
  });

export { getAllNatives, createNatives, updateNatives, getUserByNativeId };
