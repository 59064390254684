import React, { useEffect, useState, useRef } from 'react'
import { DataGrid, FileUploader, } from 'devextreme-react'
import { Column, Button as DxButton, Form, Popup, ColumnFixing, Editing, Pager, Paging, Scrolling, Summary, TotalItem, RemoteOperations, Selection, Toolbar, LoadPanel, ToolbarItem, ColumnChooser, Item as ToolBarItem, Sorting } from 'devextreme-react/data-grid';
import { Item, SimpleItem } from 'devextreme-react/form';
import { toast } from 'react-toastify';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import { createProfiles, getAllProfiles, updateProfiles, uploadPhoto } from '../../services/profiles/profiles.api';
import { Gender, Profiles } from '../../models/profiles';
import Button from 'devextreme-react/button';
import { RenderImage } from '../utils/RenderImage';
import { useAppDispatch } from '../../app/store';
import { setImageLink } from '../../features/imageModalSlice';



type Props = {
    gender: Gender
}

const BiodatasTable
    = ({ gender }: Props) => {
        const datagridRef = useRef<any>();
        let fileUploaderRef = useRef(null);

        const dispatch = useAppDispatch();


        function editCellRender(cellInfo: { data: Profiles, value: string; }) {

            return (
                <>


                    <FileUploader ref={fileUploaderRef} multiple={false} accept="image/*" uploadMode="useButtons"
                        uploadFile={
                            async (e) => {

                                await uploadPhoto(cellInfo.data.id, e);
                            }}
                        onUploadError={(e) => {
                            toast.error(e.message);
                        }}
                        onUploaded={(e) => {
                            toast.success("Image uploaded successfully");
                        }}
                    />
                </>
            );
        }
        const store = new CustomStore({
            key: 'id',
            errorHandler: (error: any) => {
                toast.error(error.response.data.msg);
            },

            load: async function (loadOptions) {
                const res = await getAllProfiles(loadOptions.skip, loadOptions.take, gender);
                return {
                    data: res.data.data,
                    totalCount: res.data.count,
                    summary: [res.data.count]
                }
            },


            insert: async function (profiles: Profiles) {
                const res = await createProfiles(profiles);
                return res.data
            },

            update: async function (id: string, profiles: Profiles,) {
                const res = await updateProfiles(id, profiles);
                return res.data
            },

            onInserted: function (e) {
                toast.success('Profiles Created Successfully');
            },

            onUpdated: function (e) {
                toast.success('Profiles Updated Successfully');
            },
        });

        // ===== or inside the DataSource =====
        const dataSource = new DataSource({
            store: store,
        });

        const genderOptions = ['MALE', 'FEMALE', 'OTHERS']
        const allowedPageSizes = [5, 10, 15, 20];

        return (
            <>
                <DataGrid
                    dataSource={dataSource}
                    columnAutoWidth={true}
                    showBorders={true}

                    rowAlternationEnabled={true}
                    columnHidingEnabled={true}
                    ref={datagridRef}
                >
                    <Sorting mode="none" />
                    <ColumnChooser enabled={true} />
                    <LoadPanel enabled={true} />
                    <RemoteOperations
                        paging={true}
                        summary={true}
                    />
                    <Paging
                        defaultPageSize={5} />
                    <Scrolling columnRenderingMode={'virtual'} rowRenderingMode="virtual" />
                    <ColumnFixing enabled={true} />
                    <Pager
                        showPageSizeSelector={true}
                        allowedPageSizes={allowedPageSizes}
                        showNavigationButtons={true}
                        showInfo={true}
                        visible={true}
                    />
                    <Toolbar>
                        <ToolBarItem
                            name="addRowButton"
                        />
                        <ToolBarItem location="after">
                            <Button
                                icon='refresh'

                                onClick={() => {
                                    dataSource.reload();
                                }} />
                        </ToolBarItem>

                    </Toolbar>


                    <Editing
                        mode="popup"
                        allowUpdating={true}
                        allowAdding={true}
                    >
                        <Popup title="Profiles Info" showTitle={true} width={700} height={525} />
                        <Form>

                            <Item itemType="group" colCount={2} colSpan={2}>
                                <SimpleItem dataField="gender" isRequired editorType={'dxSelectBox'}
                                    editorOptions={
                                        {
                                            dataSource: genderOptions
                                        }
                                    } />
                            </Item>
                            <SimpleItem dataField="photo" colSpan={2} />

                            <SimpleItem dataField="postStart" isRequired />
                            <SimpleItem dataField="postEnd" isRequired />

                            <SimpleItem dataField="isActive" helpText={'Make an Profile Acive/Inactive'} />

                        </Form>
                    </Editing>


                    <Column dataField="gender" />
                    <Column dataField="photo" width={100} cellRender={

                        (cellInfo) => {
                            return <figure className='w-sm'>
                                <a href="#image-modal" onClick={
                                    () =>
                                        dispatch(setImageLink(cellInfo.data.photo[0]))
                                } >
                                    <RenderImage imageLocation={cellInfo.data.photo[0]} imageAlt={'Faild to Load Image'} />
                                </a>
                            </figure>
                        }}
                        editCellRender={editCellRender} />


                    <Column dataField="postStart" dataType="datetime" format={'dd/MM/yyyy hh:mm aa'} />
                    <Column dataField="postEnd" dataType="datetime" format={'dd/MM/yyyy hh:mm aa'} />

                    <Column dataField="isActive" dataType="boolean" cellRender={
                        ({ value }) => {
                            return value ? <span className="badge badge-success">Active</span> : <span className="badge badge-error">Inactive</span>
                        }
                    } />

                    <Column dataField="createdAt" dataType="datetime" format={'dd/MM/yyyy hh:mm aa'} allowEditing={false} />
                    <Column dataField="updatedAt" dataType="datetime" format={'dd/MM/yyyy hh:mm aa'} allowEditing={false} />

                    <Column type="buttons" alignment="center" >
                        <DxButton name="edit"
                            render={
                                (_props: any) => {
                                    return <button className="btn btn-secondary btn-sm" onClick={() => {
                                        // @ts-ignore
                                        return dataGridRef.current?.instance.editRow(_props.rowIndex)
                                    }}>Edit</button>
                                }}
                        />

                    </Column>


                    <Summary>
                        <TotalItem column="name" summaryType="count" displayFormat="Total {0} Profiles" />

                    </Summary>
                </DataGrid>

            </>
        )
    }




export default BiodatasTable
