import React, { useState } from 'react'
import { DataGrid, LoadPanel } from 'devextreme-react'
import { useGetUserByNativeIdQuery, useUpdateUserMutation } from '../../app/services/userData';
import { Column, ColumnFixing, Pager, Paging, RemoteOperations, Scrolling, Sorting, Summary, TotalItem } from 'devextreme-react/data-grid';
import { toast } from 'react-toastify';
import BackButton from '../utils/BackButton';
import { RenderImage } from '../utils/RenderImage';
import Heading from '../utils/Heading';
import Loading from '../utils/Loading';
import { Switch } from '@headlessui/react';
import { Link } from 'react-router-dom';
import { calculateFullAge } from '../../utils/utils';
import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';
import { getUserByNativeId } from '../../services/natives/nativeApi';
import { useGetAllNativesQuery } from '../../app/services/natives';
import { nativeCount } from '../../utils/constants';

type Props = {
    nativeId: string,
}

const NativeUserTable = ({ nativeId }: Props) => {
    // const users = useGetUserByNativeIdQuery({ nativeId, 0, 20 });
    const [updateUser, { isError }] = useUpdateUserMutation();
    const allowedPageSizes = [10, 20, 50];
    const { data } = useGetAllNativesQuery({ take: nativeCount, skip: 0 });

    const store = new CustomStore({
        key: "id",

        load: async function (loadOptions) {

            const res = await getUserByNativeId(nativeId, loadOptions.skip, loadOptions.take);

            return {
                data: res.data.data,
                totalCount: res.data.totalCount,
                summary: [res.data.totalCount],
            }
        },
    });

    // ===== or inside the DataSource =====
    const dataSource = new DataSource({
        store: store,
    });

    const handleToggleSwitch = async (id: string, change: boolean) => {

        const updatedData = await updateUser({
            id: id,
            isActive: change
        }).unwrap();

        if (updatedData) {
            toast.success('User updated successfully');
        }
    }
    if (isError) {
        toast.error('Failed to update user');
    }

    const villageName = data?.find(item => item.id === nativeId)?.name;
    return (
        <>
            <BackButton />
            <Heading title={'People from ' + villageName + ' village'} />
            {
                <DataGrid
                    dataSource={dataSource}
                    columnAutoWidth={true}
                    showBorders={true}
                    rowAlternationEnabled={true}
                    columnHidingEnabled={true}
                >
                    <RemoteOperations
                        paging={true}
                        // sorting={true}
                        summary={true}
                    />
                    <Sorting mode="none" />
                    <Pager
                        showPageSizeSelector={true}
                        allowedPageSizes={allowedPageSizes}
                        showNavigationButtons={true}
                        showInfo={true}
                        visible={true}
                    />
                    <Paging
                        defaultPageSize={10} />
                    <Scrolling columnRenderingMode="virtual" rowRenderingMode='virtual' />
                    <ColumnFixing enabled={true} />


                    <Column dataField="profilePhoto"
                        width={100} cellRender={
                            (cellInfo) => {

                                return <figure className='w-sm'>
                                    <RenderImage imageLocation={cellInfo.data.profilePhoto} imageAlt={cellInfo.data.firstName} />
                                </figure>
                            }}
                    />
                    <Column dataField="firstName" cellRender={
                        (cellInfo: any) => {

                            return <Link className='link link-primary' to={`/user/${cellInfo.data.id}`}>

                                {cellInfo.data.firstName}
                            </Link>
                        }
                    } />
                    <Column dataField="lastName" />

                    <Column dataField="dob" caption="Age" calculateCellValue={
                        (cellInfo: any) => {
                            return calculateFullAge(cellInfo.dob);
                        }
                    } />

                    <Column dataField="gender" />

                    <Column dataField="guruJi" />




                    <Column
                        alignment={'center'}
                        cellRender={
                            (cellInfo) => {
                                return <div>

                                    <Switch
                                        checked={cellInfo.data.isActive}
                                        onChange={(e: boolean) => {
                                            if (!window.confirm('Are you sure you want to change the status of this user?')) {
                                                return;
                                            }
                                            return handleToggleSwitch(cellInfo.data.id, e)
                                        }}
                                        className={`${cellInfo.data.isActive ? 'bg-teal-900' : 'bg-red-700'}
relative inline-flex flex-shrink-0 h-[38px] w-[74px] border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
                                    >
                                        <span className="sr-only">Make profile Active/Inactive</span>
                                        <span
                                            aria-hidden="true"
                                            className={`${cellInfo.data.isActive ? 'translate-x-9' : 'translate-x-0'}
            pointer-events-none inline-block h-[34px] w-[34px] rounded-full bg-white shadow-lg transform ring-0 transition ease-in-out duration-200`}
                                        />
                                    </Switch>
                                </div>
                            }
                        } />
                    <Summary>
                        <TotalItem column="firstName" summaryType="count" displayFormat="Total {0} User" />

                    </Summary>
                </DataGrid>
            }

        </>
    )
}

export default NativeUserTable