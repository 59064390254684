import React, { useEffect, useState } from 'react'
import { DataGrid, } from 'devextreme-react'
import { Column, Form, Popup, ColumnFixing, Editing, Pager, Paging, Scrolling, Summary, TotalItem, RemoteOperations, Selection, Sorting } from 'devextreme-react/data-grid';
import { Item, SimpleItem } from 'devextreme-react/form';
import { toast } from 'react-toastify';

import { AdminAuth } from '../../models/admin-auth';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import { Link } from 'react-router-dom';
import { createAdminAuth, getAllAdminAuth, updateAdminAuth } from '../../services/admin-auth/admin-auth.api';



type Props = {}

const AdminAuthTable = (props: Props) => {


    const store = new CustomStore({
        key: 'id',
        errorHandler: (error: any) => {
            toast.error(error.response.data.msg);
        },

        load: async function (loadOptions) {

            const res = await getAllAdminAuth(loadOptions.skip, loadOptions.take);
            return {
                data: res.data.data,
                totalCount: res.data.count,
                summary: [res.data.count]
            }
        },

        insert: async function (adminAuth: AdminAuth) {
            const res = await createAdminAuth(adminAuth);
            return res.data
        },

        update: async function (id: string, adminAuth: AdminAuth,) {

            const res = await updateAdminAuth(id, adminAuth);
            return res.data
        },

        onInserted: function (e) {
            toast.success('Admin Account Created Successfully');
        },

        onUpdated: function (e) {
            toast.success('Admin Account Updated Successfully');
        },
    });

    // ===== or inside the DataSource =====
    const dataSource = new DataSource({
        store: store,
    });

    const allowedPageSizes = [5, 10, 15, 20];

    return (
        <>
            <DataGrid
                dataSource={dataSource}
                columnAutoWidth={true}
                showBorders={true}

                rowAlternationEnabled={true}
                columnHidingEnabled={true}
            >
                <Sorting mode="none" />
                <RemoteOperations
                    // filtering={true}
                    paging={true}
                    // sorting={true}
                    summary={true}
                // grouping={true}
                // groupPaging={true}
                />
                <Paging
                    defaultPageSize={5} />
                <Scrolling columnRenderingMode={'virtual'} rowRenderingMode="virtual" />
                <ColumnFixing enabled={true} />
                <Pager
                    showPageSizeSelector={true}
                    allowedPageSizes={allowedPageSizes}
                    showNavigationButtons={true}
                    showInfo={true}
                    visible={true}
                />
                <Editing
                    mode="popup"
                    allowUpdating={true}
                    allowAdding={true}
                >
                    <Popup title="Admin Account Info" showTitle={true} width={700} height={525} />
                    <Form elementAttr={
                        {
                            autoComplete: "off"
                        }
                    } >
                        <SimpleItem dataField="firstName" />
                        <SimpleItem dataField="lastName" />

                        <SimpleItem dataField="email" colSpan={2} />
                        <SimpleItem dataField="password" colSpan={2} editorOptions={
                            {
                                mode: 'password',
                                showClearButton: true,

                            }
                        }
                            helpText={
                                'Clear the password field before entering a new password. New password will be hashed and stored in the database.'
                            } />

                        <Item itemType="group" colCount={2} colSpan={2}>

                            <SimpleItem dataField="isActive" />
                            <SimpleItem dataField="isAdmin" />
                        </Item>

                    </Form>
                </Editing>
                <Column dataField="firstName" dataType={'firstName'} />
                <Column dataField="lastName" dataType={'lastName'} />

                <Column dataField="email" dataType={'email'} validationRules={
                    [{
                        type: 'email',
                        message: 'Please enter a valid email address'
                    }]
                } />
                <Column dataField="password" visible={false} />
                <Column dataField="isAdmin" dataType="boolean" cellRender={
                    ({ value }) => {
                        return value ? <span className="badge badge-success">Admin</span> : <span className="badge badge-warning">Non Admin</span>
                    }
                } />
                <Column dataField="isActive" dataType="boolean" cellRender={
                    ({ value }) => {
                        return value ? <span className="badge badge-success">Active</span> : <span className="badge badge-error">Inactive</span>
                    }
                } />
                <Column dataField="createdAt" dataType="datetime" format={'dd/MM/yyyy hh:mm aa'} allowEditing={false} />
                <Column dataField="updatedAt" dataType="datetime" format={'dd/MM/yyyy hh:mm aa'} allowEditing={false} />
                <Summary>
                    <TotalItem column="email" summaryType="count" displayFormat="Total {0} Admin Accounts" />

                </Summary>
            </DataGrid>

        </>
    )
}

export default AdminAuthTable