import React from 'react'
import { DummyUser } from '../../models/user';
import { SubmitHandler, useForm } from 'react-hook-form'
import { useCreateDummyUserMutation } from '../../app/services/userData';
import { toast } from 'react-toastify';
import { ErrorMessage } from '@hookform/error-message';
import { useGetAllNativesQuery } from '../../app/services/natives';
import Heading from '../utils/Heading';
import { nativeCount } from '../../utils/constants';

type Props = {
}





const Adduser = (props: Props) => {
    const { register, reset, handleSubmit, formState: { errors } } = useForm<DummyUser>();
    const [createDummyUser, { isLoading }] = useCreateDummyUserMutation()
    const { data } = useGetAllNativesQuery({ take: nativeCount, skip: 0 });

    const onSubmit: SubmitHandler<DummyUser> = async (formData, e) => {
        try {
            if (formData.nativeVillageId === "null") {
                formData.nativeVillageId = null;
            }

            formData.mobile = "+91" + formData.mobile;

            await createDummyUser(formData).unwrap();
            toast.success('User Created Successfully')
            reset();
        } catch (error) {

            toast.error((error as any).data.msg)
        }

    }




    return (
        <div>
            <form className="form-control bg-gray-100 rounded-md p-12 max-w-md m-auto w-full" onSubmit={handleSubmit(onSubmit)}>

                <Heading title="Add User" />

                <label className="label">
                    <span className="label-text">First Name</span>
                </label>

                <input type="text" placeholder="First Name" className="input input-bordered" {...register("firstName", { required: "This is required." })} />

                <ErrorMessage errors={errors} name="firstName" render={
                    ({ message }) => {
                        return <span className="italic text-red-300">{message}
                        </span>
                    }} />
                <label className="label">
                    <span className="label-text">Last Name</span>
                </label>

                <input type="text" placeholder="Last Name" className="input input-bordered" {...register("lastName", { required: "This is required." })} />

                <ErrorMessage errors={errors} name="lastName" render={
                    ({ message }) => {
                        return <span className="italic text-red-300">{message}
                        </span>
                    }} />


                <label className="label">
                    <span className="label-text">Gender</span>
                </label>

                <select placeholder="Gender" className="select w-full max-w-xs input-bordered" {...register("gender", { required: "This is required." })} >
                    <option>MALE</option>
                    <option>FEMALE</option>
                </select>

                <ErrorMessage errors={errors} name="gender" render={
                    ({ message }) => {
                        return <span className="italic text-red-300">{message}
                        </span>
                    }} />
                <label className="label">
                    <span className="label-text">Mobile</span>
                </label>

                <input type="text" placeholder="Mobile" className="input input-bordered"{...register("mobile", {
                    required: "This is required.",
                    pattern: {
                        value: /^[0-9]{10}$/, message: "Mobile number should be 10 digits"
                    }


                })} />

                <ErrorMessage errors={errors} name="mobile" render={
                    ({ message }) => {
                        return <span className="italic text-red-300">{message}
                        </span>
                    }} />

                <label className="label">
                    <span className="label-text">Native Village</span>
                </label>
                <select className="select input-bordered" defaultValue={'null'} {...register("nativeVillageId", {
                    required: "This is required.",
                    validate: {
                        isNativeVillage: (value) => {
                            if (value === 'null') {
                                return "This is required."
                            }
                        }
                    },
                })}>
                    <option value={"null"} disabled>Select Native Village</option>
                    {data?.map(item => <option key={item.id} value={item.id}>{item.name}</option>)}
                </select>
                <ErrorMessage errors={errors} name="nativeVillageId" render={
                    ({ message }) => {
                        return <span className="italic text-red-300">{message}
                        </span>
                    }} />
                <div className="form-control mt-6 " >
                    <button className={`btn btn-primary ${isLoading ? 'loading' : ''}`} type='submit'>Submit</button>
                </div>
            </form >
        </div >
    )
}

export default Adduser