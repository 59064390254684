import { ErrorMessage } from '@hookform/error-message';
import { skipToken } from '@reduxjs/toolkit/dist/query/react';
import React, { useEffect, useRef, useState } from 'react'
import { useForm, SubmitHandler } from "react-hook-form";
import { GoVerified } from 'react-icons/go';
import { MdLocationOn } from 'react-icons/md';
import { Link, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useGetAllNativesQuery } from '../../app/services/natives';
import { useAddChildMutation, useAddFatherMutation, useAddMotherMutation, useAddSiblingsMutation, useAddSpouseMutation, useSearchUsersQuery, useSearchActiveUsersQuery } from '../../app/services/userData';
import { Gender } from '../../models/profiles';
import { nativeCount } from '../../utils/constants';
import { getNativeName } from '../../utils/utils';
import Loading from '../utils/Loading';
import { RenderProfileImage } from '../utils/RenderImage';

type Props = {
    relation: string,
    id: string,
}
type Inputs = {
    searchString: string,
};
const AddRelationSearch = (props: Props) => {
    const [searchParams, setSearchParams] = useSearchParams({});
    // const [searchString, setSearchString] = useState('');

    const { data, error, isFetching } = useSearchActiveUsersQuery(searchParams.get('search') ?? skipToken);

    const { register, handleSubmit, setFocus, setValue, resetField, trigger, formState: { errors } } = useForm<Inputs>();
    const onSubmit: SubmitHandler<Inputs> = (searchData) => {
        let newUser = searchData.searchString;
        if (!newUser) return;


        setSearchParams({ search: searchData.searchString });

    };


    useEffect(() => {
        if (searchParams.get('search')) {

            setValue('searchString', searchParams.get('search') ?? '')
            setFocus("searchString");
        }

    }, [])



    return (
        <div className='w-full mr-10'>
            <form className="form-control" autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                <div className="input-group relative flex justify-center items-center ">
                    <input type="text" placeholder="Search…" className="input w-3/4 input-bordered"{...register("searchString", {
                        required: true, minLength: {
                            value: 3,
                            message: "Search string must be at least 3 characters long"
                        }
                    })} >

                    </input>

                    <button className="btn btn-primary" type="submit">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" /></svg>
                    </button>



                </div>
                <ErrorMessage
                    errors={errors}
                    name="searchString"
                    render={({ message }) => <p className='text-red-500 text-center font-semibold'>{message}</p>}
                />
            </form>

            {
                error ? <div className="text-red-400 text-3xl text-center font-bold">Error Fetching Data</div> : null
            }
            {isFetching ? <Loading /> : null}
            <div className='flex flex-wrap justify-center items-center'>
                {data && data.data.length !== 0 ? data.data.map((user: any) => (
                    <ProfileCard key={user.id} profileData={user} relation={props.relation} id={props.id} />
                )) : <p>---no user--</p>}
            </div>

        </div>
    )
}



type ProfileCardProps = {
    relation: string,
    id: string,
    profileData: {
        id: string,
        profilePhoto: string,
        firstName: string,
        lastName: string,
        gender: Gender,
        nativeVillageId: string,
        verified: boolean,
        isDeceased: boolean
    },

}


const ProfileCard = ({ profileData, relation, id }: ProfileCardProps) => {
    const natives = useGetAllNativesQuery({ take: nativeCount, skip: 0 });

    const [addChild] = useAddChildMutation();
    const [addSibling] = useAddSiblingsMutation();

    const [addFather] = useAddFatherMutation();
    const [addMother] = useAddMotherMutation();
    const [addSpouse] = useAddSpouseMutation();

    return (

        <div className="card card-compact w-40 bg-base-100 shadow-xl m-3">
            <figure className='p-2 w-32 m-auto'>
                <RenderProfileImage imageLocation={profileData.profilePhoto} firstName={profileData.firstName} lastName={profileData.lastName} />
            </figure>
            <div className="card-body p-3">
                {/* <h2 className="card-title m-auto">{profileData.firstName} {profileData.lastName}</h2> */}
                <h2 className="flex items-center">{profileData.isDeceased ? '(late)' : null} {profileData.firstName} {profileData.lastName}&nbsp;{profileData.verified && <GoVerified size={15} className="rounded-full text-blue-600" />} </h2>
                {profileData.nativeVillageId ? <p className='flex items-center'><MdLocationOn /> {getNativeName(profileData.nativeVillageId, natives?.data)}</p> : null}

                <button className="card-actions justify-between items-center btn btn-sm" onClick={() => {
                    switch (relation) {
                        case 'child':
                            addChild({ id: id, childId: profileData.id }).unwrap().then(() => {
                                toast.success('Added Child')
                            })
                            break;
                        case 'sibling':
                            addSibling({ id: id, siblingId: profileData.id }).unwrap().then(() => {
                                toast.success('Added Sibling')
                            })
                            break;
                        case 'father':
                            addFather({ id: id, fatherId: profileData.id }).unwrap().then(() => {
                                toast.success('Added Father')
                            })
                            break;
                        case 'mother':
                            addMother({ id: id, motherId: profileData.id }).unwrap().then(() => {
                                toast.success('Added Mother')
                            })
                            break;
                        case 'spouse':
                            addSpouse({ id: id, spouseId: profileData.id }).unwrap().then(() => {
                                toast.success('Added Spouse')
                            })
                            break;
                        default:
                            break;
                    }

                }}>
                    Add {relation}
                </button>
            </div>
        </div>

    )
}


export default AddRelationSearch