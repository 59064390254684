import React from 'react'
import { useParams } from 'react-router-dom';
import NativeUserTable from '../components/natives/NativeUserTable'

type Props = {}

const NativeUser = (props: Props) => {

    let params = useParams();
    const id = params.id!;

    return (
        <div>

            <NativeUserTable nativeId={id} />

        </div>
    )
}

export default NativeUser